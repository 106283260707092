import React from "react"

import Job from "../components/job"
import { JobFeature, JobRole } from "../components/job-components"

const JobTechnicalSalesManager = () => {
  return (
    <Job
      title="Technical Sales Manager, ML Software"
      location="Austria/Hybrid"
      workingTime="Full-time/Part-time"
      startDate="Q4 2022"
      annualSalary="EUR 42,000"
    >
      <h3 className="my-5">Working at Celantur</h3>
      <JobFeature>
        <strong>Our customers are world-class companies.</strong> We serve a
        wide range of Fortune 500 companies, SMEs and municipalities. Therefore
        we’re exposed to a lot of exciting and highly technical use-cases in
        Reality Capture, Construction, Automotive and Big Data.
      </JobFeature>
      <JobFeature>
        Our customers process millions of images &amp; videos, and trust us to
        make them privacy-compliant. Reliability and scalability are key.
      </JobFeature>
      <JobFeature>Together we solve a global problem and</JobFeature>
      <JobFeature>
        As a growing startup, everyone has to take over responsibilities. Your{" "}
        <strong>initiative spirit</strong> will be a major contribution to the
        team’s success!
      </JobFeature>
      <JobFeature>
        As a team, we share a variety of interests: chess, books, technology,
        stock markets, just to name a few. For this reason, we leave dedicated
        and spontaneous moments to discuss, exchange learnings and of course,
        have fun!
      </JobFeature>

      <JobRole>
        <JobFeature>
          Prospect for new customers and resellers who will benefit from our
          products or services
        </JobFeature>
        <JobFeature>
          Approach and convince clients (via LinkedIn, email and phone) that our
          solution is the best fit for their challenges in terms of quality,
          price and delivery
        </JobFeature>
        <JobFeature>Analyze economic feasibility of new projects</JobFeature>
        <JobFeature>
          Have experience with IT projects, key concepts of software
          development, requirements engineering
        </JobFeature>
        <JobFeature>Meet quarterly sales targets and report results</JobFeature>
        <JobFeature>
          Coordinate sales outreach by building LinkedIn audience, attend trade
          shows, conferences and other events
        </JobFeature>
        <JobFeature>
          Develop trustful long-term relationships with clients, by
          understanding their requirements and providing after-sales support
        </JobFeature>
        <JobFeature>
          Work closely with our R&amp;D and product management team, provide
          market insights and requirements to further develop our products
        </JobFeature>
      </JobRole>

      <h3 className="my-5">What we offer</h3>
      <JobFeature>
        Hybrid working policy (office presence and home office){" "}
      </JobFeature>
      <JobFeature>
        Short feedback loops and career opportunities as part of a growing
        startup
      </JobFeature>
      <JobFeature>
        An office at Tabakfabrik Linz - one of the hearts of Austria’s start-up
        scene - with a diverse set of great places for lunch and leisure (e.g.
        Donaulände)
      </JobFeature>
      <JobFeature>
        Work with our young and international team on high-tech solutions and
        services
      </JobFeature>
      <JobFeature>
        Living and working in{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.workinaustria.com/en/why-austria"
        >
          Austria
        </a>
        , the country with the <strong>highest quality of life</strong> in{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.workinaustria.com/en/why-austria/quality-of-living"
        >
          Europe
        </a>
        . Check this link to learn more about why living in Austria.
      </JobFeature>
      <JobFeature>
        The minimum salary for this position is EUR 42,000 gross per year plus
        bonus based on full-time employment (38.5 h/week). We offer a higher
        salary in line with your qualifications and experience.
      </JobFeature>
    </Job>
  )
}

export default JobTechnicalSalesManager
